import React from 'react'
import styled from 'styled-components'
import { rem, flex, is, value, position } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import Image from 'components/image'
import BaseCard from 'components/base-card'
import DiagonalLine from 'components/diagonal-line'

const { colors, fonts } = theme
const { ghost, bone, black } = colors

export const Title = styled.span`
  ${flex('column', 'center', 'center')}
  ${position('absolute', 0)}
  transition: all 250ms ease;
  z-index: 2;

  span {
    background-color: ${value('bg')};
    border-radius: ${rem(3)};
    color: ${value('color')};
    font-family: ${fonts.secondary};
    font-size: ${rem(18)};
    font-weight: 700;
    padding: 0 ${rem(8)};

    ${media.small`
      font-size: ${rem(22)};
      padding: 0 ${rem(12)};
    `}

    ${media.medium`
      font-size: ${rem(24)};
    `}
  }
`

export const Description = styled.span`
  display: none;

  ${media.xsmall`
    ${flex('column', 'center', 'center')}
    ${position('absolute', -1)}
    background: ${ghost};
    border-radius: ${rem(3)};
    -webkit-transform: scaleX(0);
    transform: scaleX(0) skewX(60deg);
    transition: all 250ms ease;
    z-index: 3;

    span {
      background: ${ghost};
      color: ${black};
      font-family: ${fonts.primary};
      font-size: ${rem(12)};
      font-weight: 400;
      margin: 0 ${rem(16)};
      padding: ${rem(8)} 0;
      position: relative;
      z-index: 2;
    }
  `}

  ${media.small`
    span {
      font-size: ${rem(16)};
    }
  `}
`

const Card = styled(BaseCard)`
  padding-bottom: calc(100% * ${value('ratio')});

  a {
    ${flex('column', 'center', 'center')}
    ${position('absolute', 0)}
    background: ${ghost};
    border-radius: ${rem(4)};
    margin: 0;
    overflow: hidden;
    padding: ${rem(16)};
    text-decoration: none;

    .gatsby-image-wrapper {
      ${position('absolute !important', 0)}
      border-radius: ${rem(4)};
      height: auto;
      overflow: hidden;
      width: auto;
    }

    svg {
      opacity: 0;
      transform: scale(0);
      transition: all 400ms ease;
      transition-delay: 100ms;
    }

    :hover,
    :focus {
      box-shadow: none;

      svg {
        opacity: 1;
        transform: none;
      }

      ${Title} {
        opacity: 1;
        transform: none;
      }

      ${Description} {
        border: ${rem(2)} solid ${bone};
        transform: none;
      }
    }
  }

  ${is('scrollable')`
    margin-left: ${rem(8)};
    width: 70vw;

    a {
      bottom: ${rem(64)};
      overflow: hidden;
    }

    ${media.xsmall`
      width: 40vw;
    `}

    ${media.medium`
      margin-left: 0;
      margin-right: ${rem(12)};
      width: calc(33.33% - ${rem(8)});

      :nth-child(3n + 2) {
        width: calc(33.34% - ${rem(8)});
      }

      :last-child {
        margin-right: 0;
      }
    `}
  `}
`

export default ({
  link: Link,
  title,
  description,
  image,
  bg,
  color,
  ratio = 0,
  scrollable = false,
}) => (
  <Card ratio={ratio} scrollable={scrollable}>
    <Link>
      <Image file={image} />
      <Title bg={bg} color={color}>
        <span>{title}</span>
      </Title>
      <Description>
        <DiagonalLine />
        <span>{description}</span>
      </Description>
    </Link>
  </Card>
)
