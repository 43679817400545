import React from 'react'
import Layout from 'layouts/blog'
import BlogGrid from 'components/blog-grid'

export default () => (
  <Layout
    title="Adventures of a Web Developer & Poet"
    subtitle="Words by Douglas Waltman II"
    description=""
  >
    <BlogGrid />
  </Layout>
)
