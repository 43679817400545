import React from 'react'
import styled from 'styled-components'
import { rem, grid } from 'styled-tidy'
import { Link } from 'gatsby'
import fetchArticles from 'lib/fetch-articles'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import LinkCard, { Title } from 'components/link-card'

const { white, black } = theme.colors

const Container = styled.ul`
  ${grid(2, 16)}
  margin: 0;
  padding: 0;
  text-align: left;
  user-select: none;
  width: 100%;

  ${Title} span {
    font-size: ${rem(16)} !important;
    margin: 0 ${rem(12)};
  }

  ${media.small`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.medium`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.large`
    grid-column-gap: ${rem(24)};
    grid-row-gap: ${rem(24)};
    grid-template-columns: repeat(3, 1fr);
  `}
`

export default ({ limit = 0 }) => {
  const articles = fetchArticles({ limit, reverse: true })

  return (
    <Container>
      {articles.map(({ path, title, description }) => (
        <LinkCard
          key={path}
          title={title}
          description={description}
          image={path.replace(/\/blog\/\d+\/(.*)/, '$1.jpg')}
          color={white}
          bg={black}
          link={({ children }) => <Link to={path}>{children}</Link>}
          ratio={1}
        />
      ))}
    </Container>
  )
}
